import axios from 'axios';
import AuthFactory from '@/factories/AuthFactory';
import AuthRepository from '@/repositories/AuthRepository';
import ILoginForm from '@/types/ILoginForm';
import IRegistrationForm from '@/types/IRegistrationForm';
import IRemindPasswordForm from '@/types/IRemindPasswordForm';
import ICountry from '@/types/ICountry';
import IPasswordForm from '@/types/IPasswordForm';
import IUserCredentials from '@/types/IUserCredentials';
import {getLocalStorage, removeLocalStorage, setLocalStorage} from '@/utils/localStorage';
import router from '@/router';
import UserService from '@/services/UserService';
import User from '@/store/modules/User';

export default class AuthService {
    static parseJwt(token: string) {
        if (token) {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        }
        return '';
    };

    static async login(credentials: ILoginForm): Promise<any> {
        const userCredentials: IUserCredentials = AuthFactory.toUserCredentials(await AuthRepository.login(credentials));
        setLocalStorage('token', `${userCredentials.token_type} ${userCredentials.access_token}`);
        setLocalStorage('refresh_token', `${userCredentials.token_type} ${userCredentials.refresh_token}`);

        const now = new Date();
        const secondsSinceEpoch = Math.round(now.getTime() / 1000);
        setLocalStorage('expires_at', secondsSinceEpoch + userCredentials.expires_in - 60);

        axios.defaults.headers.common.Authorization = `${userCredentials.token_type} ${userCredentials.access_token}`;
    }

    static async refreshToken(): Promise<any> {
        const userCredentials: IUserCredentials = AuthFactory.toUserCredentials(await AuthRepository.refreshToken(getLocalStorage('refresh_token').split(' ')[1]));
        setLocalStorage('token', `${userCredentials.token_type} ${userCredentials.access_token}`);
        setLocalStorage('refresh_token', `${userCredentials.token_type} ${userCredentials.refresh_token}`);

        const now = new Date();
        const secondsSinceEpoch = Math.round(now.getTime() / 1000);
        setLocalStorage('expires_at', secondsSinceEpoch + userCredentials.expires_in - 60);

        axios.defaults.headers.common.Authorization = `${userCredentials.token_type} ${userCredentials.access_token}`;
    }

    static async logout(): Promise<void> {
        try {
            // await CompaniesService.logoutAs()
            await AuthRepository.logout();
        } finally {
            removeLocalStorage('token');
            removeLocalStorage('refresh_token');
            removeLocalStorage('expires_at');
            UserService.setUser(new User());
            // removeLocalStorage('isLoggedAs')
            axios.defaults.headers.common.Authorization = 'Basic YnJvd3Nlcjo=';
            await router.push({name: 'Login'});
        }
    }

    static register(credentials: IRegistrationForm): Promise<any> {
        return AuthRepository.register(credentials);
    }

    static remindPassword(credentials: IRemindPasswordForm): Promise<any> {
        return AuthRepository.remindPassword(credentials);
    }

    static resetPassword(credentials: IPasswordForm, token: string): Promise<any> {
        return AuthRepository.resetPassword(credentials, token);
    }

    static async getRegisterForm(): Promise<ICountry[]> {
        const {data} = await AuthRepository.getRegisterForm();
        return AuthFactory.toRegisterForm(data.countries);
    }

    static async updatePassword(credentials: IPasswordForm) {
        if (credentials.newPassword === credentials.repeatedPassword) {
            return AuthRepository.updatePassword(credentials.newPassword);
        }
        throw new Error();
    }
}
