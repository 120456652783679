export const cleanupEmptyProps = (obj: object) => {
  const newObj = copyObject(obj);
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === undefined || newObj[key] === null || newObj[key] === '') {
      delete newObj[key];
    }
  });
  return newObj;
};

export const nullEmptyProps = (obj: object) => {
  const newObj = copyObject(obj);
  Object.keys(newObj).forEach((key) => {
    if (Array.isArray(newObj[key]) && !newObj[key].length || newObj[key] === undefined || newObj[key] === '') {
      newObj[key] = null;
    }
  });

  return newObj;
};

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const makeCounter = () => {
  let currentCount = 1;

  return () => currentCount++;
};

export const formatNumberWithSpaces = (x: number | string, toFixed: number | null = null) => {
  let num: number | string = x;
  if (toFixed) {
    num = Number(num).toFixed(toFixed);
  }
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\xa0');
  return parts.join('.');
};

export const replaceCommaToDot = (value: string | number): number => {
  return Number(String(value).replace(',', '.'));
};

export const copyObject = (obj: object) => {
  return JSON.parse(JSON.stringify(obj));
};

export const removeItems = (arr: any[], value: any) => {
  let i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
};

export const distinctArrayOfPrimitives = (array: (string | number | bigint | symbol | boolean | undefined)[]) => {
  return [...new Set(array)];
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getObjectValueByPath = (path: string, obj: object, separator: string = '.') => {
  if (!path) {
    return obj;
  }
  if (obj) {
    return path.split(separator).reduce((o: any, i) => o[i], obj);
  }
  return null;
};

export const camelCaseToUnderscoreCase = (str: string) => {
  return str.replace(/(?:^|\.?)([A-Z])/g, (x, y) => '_' + y.toLowerCase()).replace(/^_/, '')
}
