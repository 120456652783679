import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {i18n} from '@/plugins/i18n';
import TranslateResult = VueI18n.TranslateResult;

export enum NotificationTypes {
  error = 'error',
  warn = 'warn',
  success = 'success'
}

export const notify = (
  type: NotificationTypes,
  title: string | TranslateResult,
  text: string | TranslateResult = '') => {
  Vue.notify({
    group: 'foo',
    type,
    title: title as string,
    text: text as string,
  });
};

export const resolveError = (error: any, action: string) => {
  const message = error?.response?.data?.message;
  notify(
    NotificationTypes.error,
    i18n.t(message || `SYSTEM_FAILED_TO_${action.toUpperCase()}`),
  );
};
