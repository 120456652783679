export const authRoutes = [
  {
    path: 'login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: 'registration',
    name: 'Registration',
    component: () => import('../views/Registration.vue'),
  },
  {
    path: 'remind-password',
    name: 'RemindPassword',
    component: () => import('../views/RemindPassword.vue'),
  },
  {
    path: 'reset-password/:token',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    path: 'activate-account/:token',
    name: 'Verification',
    component: () => import('../views/Verification.vue'),
  },
];
