import IUserCredentials from '@/types/IUserCredentials';
import ICountry from '@/types/ICountry';
import IUserInfo from '@/types/IUserInfo';
import ITerm from '@/types/ITerms';

export default class AuthFactory {
  static toUserCredentials({data}: any): IUserCredentials {
    return {
      access_token: data.access_token,
      expires_in: data.expires_in,
      refresh_token: data.refresh_token,
      scope: data.scope,
      token_type: data.token_type,
    };
  }

  static toUserInfo({data}: any): IUserInfo {
    return {
      id: data.id,
      peopleId: data.peopleId,
      firstName: data.firstName,
      lastName: data.lastName,
      companyName: data.companyName,
      companyCurrency: data.companyCurrency,
      companyId: data.companyId,
      mail: data.mail,
      active: data.active,
      permissions: data.permissions,
      role: data.role,
      actualStep: data.actualStep,
      configurationCompleted: data.configurationCompleted
    };
  }

  static toRegisterForm(data: any): ICountry[] {
    return data.map((item: any) => ({
      countryCode: item.countryCode,
      countryId: item.countryId,
      countryName: item.countryName,
      currencyISO: item.currencyISO,
    }));
  }

  static toTerms(data: any): ITerm[] {
    let termsObject: ITerm[] = [];
    if (data.data && Array.isArray(data.data.content)) {
      termsObject = data.data.content;
    } else if (data.data) {
      termsObject = data.data;
    }
    return termsObject.map((item: any) => ({
      description: item.description,
      link: item.link,
      name: item.name,
      termId: item.termId,
      termType: item.termType,
      validFrom: item.validFrom,
      acceptedAt: item.acceptedAt || undefined,
    }));
  }
}
