import {ILanguage} from '@/types/TranslationTypes';
import axios from 'axios';

export default class LanguageRepository {
  static getAll() {
    return axios.get('language');
  }

  static getAllPublic() {
    return axios.get('public/translation/languages');
  }

  static store(payload: ILanguage) {
    return axios.post('language', payload);
  }

  static update(id: number | string, payload: ILanguage) {
    return axios.put(`language/${id}`, payload);
  }

  static delete(id: number | string) {
    return axios.delete(`language/${id}`);
  }
}
