import axios from 'axios';

export default class TranslationRepository {
  static getAll(languageId: number | string) {
    return axios.get(`translation/${languageId}`);
  }

  static getAllPublic(languageId: number | string) {
    return axios.get(`public/translation/${languageId}`);
  }

  static storeOrUpdate(languageId: number, labelId: number, payload: { translation: string }) {
    return axios.put(`translation/${languageId}/${labelId}`, payload);
  }

  static delete(id: number | string) {
    return axios.delete(`translation/delete/${id}`);
  }
}
