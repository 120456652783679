import axios from 'axios';

export default class LabelRepository {
  static getAll() {
    return axios.get(`label`);
  }

  static store(label: string) {
    return axios.post('label', {label});
  }

  static update(labelId: number | string, payload: { label: string }) {
    return axios.patch(`label/${labelId}`, payload);
  }

  static delete(id: number | string) {
    return axios.delete(`label/${id}`);
  }
}
