import {Module, Mutation, VuexModule} from 'vuex-module-decorators';
import IUserInfo from '@/types/IUserInfo';
import ITerm from '@/types/ITerms';

@Module({
    namespaced: true,
    name: 'user',
})
export default class User extends VuexModule implements IUserInfo {
    permissions: string[] = [];
    companyId: number = 0;
    companyName: string = '';
    companyCurrency: string = '';
    firstName: string = '';
    id: number = 0;
    lastName: string = '';
    mail: string = '';
    role: string = '';
    termsToAccept: ITerm[] = [];
    isLoggedAs: boolean = false;
    active: boolean = true;
    peopleId: string = '';
    actualStep: number = -1;
    configurationCompleted: boolean = false;

    constructor() {
        super({});
        this.permissions = [];
        this.companyId = 0;
        this.companyName = '';
        this.companyCurrency = '';
        this.firstName = '';
        this.id = 0;
        this.lastName = '';
        this.mail = '';
        this.role = '';
        this.termsToAccept = [];
        this.isLoggedAs = false;
        this.active = true;
        this.peopleId = '';
        this.actualStep = -1;
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    get getPermissions() {
        return this.permissions;
    }

    get getTermsToAccept() {
        return this.termsToAccept;
    }

    get isUser() {
        return !!this.id;
    }

    get getCompanyCurrency() {
        return this.companyCurrency;
    }

    @Mutation
    setUser(newUser: IUserInfo): void {
        this.permissions = newUser.permissions;
        this.companyId = newUser.companyId;
        this.companyName = newUser.companyName;
        this.companyCurrency = newUser.companyCurrency;
        this.firstName = newUser.firstName;
        this.id = newUser.id;
        this.lastName = newUser.lastName;
        this.mail = newUser.mail;
        this.role = newUser.role;
        this.actualStep = newUser.actualStep;
    }

    @Mutation
    setUserActualStep(step: number): void {
        this.actualStep = step
    }

    @Mutation
    setTermsToAccept(terms: ITerm[]) {
        this.termsToAccept = terms;
    }

    @Mutation
    setIsLoggedAs(isLoggedAs: boolean): void {
        this.isLoggedAs = isLoggedAs;
    }

}
