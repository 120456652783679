import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import {authRoutes} from '@/router/authRoutes';
import {getLocalStorage} from '@/utils/localStorage';
import {isPermission} from '@/utils/perrmissions';
import store from '@/store/index';
import UserService from '@/services/UserService';
import AuthService from '@/services/AuthService';
import {translateApp} from '@/plugins/i18n';
import CompanyService from '@/services/CompanyService';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: {name: 'Home'},
    name: 'MainLayout',
    beforeEnter: async (to, from, next) => {
      await translateApp();
      next();
    },
    component: () => import(/* webpackChunkName: "main" */ '../layouts/MainLayout.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Home',
        component: () => import(/* webpackChunkName: "main" */ '../views/Dashboard.vue'),
      },
      {
        path: 'configuration',
        name: 'Configuration',
        component: () => import(/* webpackChunkName: "main" */ '../views/Configuration/Configuration.vue'),
      },
      {
        path: 'configuration/oauth/:apiType',
        name: 'ConfigurationOAuth',
        component: () => import('@/views/Configuration/ConfigurationOAuth.vue'),
      },
      {
        path: 'company',
        name: 'Company',
        meta: {
          permissions: ['company.view'],
        },
        component: () => import('@/views/Company/Company.vue'),
      },
      {
        path: 'company/config',
        name: 'CompanyConfig.vue',
        meta: {
          permissions: ['company.view'],
        },
        component: () => import('@/views/Company/CompanyConfig.vue'),
      },
      {
        path: 'analyses',
        name: 'Analyses',
        meta: {
          permissions: ['analysis.view'],
        },
        component: () => import('@/views/Analyses.vue'),
      },
      {
        path: 'reports',
        name: 'Reports',
        meta: {
          permissions: ['report.view'],
        },
        component: () => import('@/views/Reports.vue'),
      },
      {
        path: 'margin',
        name: 'Margin',
        meta: {
          permissions: ['margin.view'],
        },
        component: () => import('@/views/Margin.vue'),
      },
      {
        path: 'reports/:id',
        name: 'ReportShow',
        props: true,
        meta: {
          permissions: ['report.view'],
        },
        component: () => import('@/views/ReportShow.vue'),
      },
      {
        path: 'stock-movements',
        name: 'StockMovements',
        meta: {
          permissions: ['stock.view'],
        },
        component: () => import('@/views/StockMovements.vue'),
      },
      {
        path: 'stock',
        name: 'Stock',
        meta: {
          permissions: ['stock.view'],
        },
        component: () => import('@/views/Stock.vue'),
      },
      {
        path: 'stock-international-movements',
        name: 'StockInternationalMovements',
        meta: {
          permissions: ['stock.view'],
        },
        component: () => import('@/views/StockInternationalMovements.vue'),
      },
      {
        path: 'companies',
        name: 'Companies',
        meta: {
          permissions: ['admin.company_view'],
        },
        component: () => import('@/views/Companies.vue'),
      },
      {
        path: 'users',
        name: 'Users',
        meta: {
          permissions: ['admin.user_view'],
        },
        component: () => import('@/views/Users.vue'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/Profile.vue'),
      },
      {
        path: 'subscription',
        name: 'Subscription',
        component: () => import('@/views/Subscription.vue'),
      },
      {
        path: 'translations',
        name: 'Translations',
        meta: {
          permissions: ['admin.translation'],
        },
        component: () => import('@/views/Translations.vue'),
      },
      {
        path: 'tkw',
        name: 'Tkw',
        meta: {
          permissions: ['tkw.view'],
        },
        component: () => import('@/views/Tkw.vue'),
      },
      {
        path: 'repricing',
        name: 'Repricing',
        meta: {
          permissions: ['public'],
        },
        component: () => import('@/views/Repricing.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: {name: 'Home'},
  },
  {
    path: '/auth',
    redirect: {name: 'Login'},
    name: 'AuthLayout',
    beforeEnter: async (to, from, next) => {
      await translateApp();
      next();
    },
    component: () => import(/* webpackChunkName: "auth" */ '../layouts/AuthLayout.vue'),
    children: authRoutes,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

// Check user authorization
router.beforeEach(async (to, from, next) => {
  let step:any = -1;
  const isAuthorized = !!getLocalStorage('token');
  if (isAuthorized && !store.state.user.id) {
    try {
      await UserService.getCurrentUser();
      step = await CompanyService.getActualStep();
    } catch (e) {
      try {
        await AuthService.refreshToken();
      } catch (e) {
        await AuthService.logout();
      }
    }
  }
  if (!isAuthorized && !to.path?.startsWith('/auth/')) {
    next({name: 'Login'});
  } else if (step >= 1 && step <= 3 && !to.path?.startsWith('/configuration/oauth')) {
    next({ name: 'Configuration' })
  } else if (step === 5) {
    next({ name: 'Subscription' })
  }

  // else if (isAuthorized && (to.path?.startsWith('/dashboard') || (to.meta?.permissions && !isPermission(to.meta.permissions, store.state.user.permissions)))) {
  //   next({name: 'Configuration'});
  // }
  else if (isAuthorized && (to.path?.startsWith('/auth/') || (to.meta?.permissions && !isPermission(to.meta.permissions, store.state.user.permissions)))) {
    next({name: 'Home'});
  } else {
    next();
  }
});

export default router;
