import store from '@/store/index';

export const isPermission = (pagePermissions: string[], userPermissions: string[] = store.getters['user/getPermissions']): boolean => {
  if (pagePermissions) {
    const result = pagePermissions.filter((pagePermission) => {
      return userPermissions.some((permission) => permission === pagePermission) || pagePermission === 'public';
    });
    return !!result.length;
  }
  return false;
};

