import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import pl from 'vuetify/src/locale/pl';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify);

const opts = {
  lang: {
    locales: {
      pl,
      en,
    },
    current: 'pl',
  },
  theme: {
    themes: {
      light: {
        primary: '#6573E0',
        success: '#1DC090',
        error: '#FD4984',
        info: '#8993e0',
      },
    },
  },
};

export default new Vuetify(opts);
