import {Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {IFormsDTO} from '@/types/tkw';
import {copyObject} from '@/utils/universalUtils';

@Module({
  namespaced: true,
  name: 'form',
})
export default class User extends VuexModule {
  tkw!: IFormsDTO

  @Mutation
  setTkwForm(data: IFormsDTO) {
    this.tkw = copyObject(data)
  }
}
