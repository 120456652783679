import Vue from 'vue';
import './plugins/axios';
import './plugins/i18n';
import './styles/global.scss';
import App from './App.vue';
import router from './router';
import store from './store';
import Notifications from 'vue-notification';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import {i18n} from './plugins/i18n';

// @ts-ignore
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

Vue.use(Notifications);

Vue.config.productionTip = true;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
