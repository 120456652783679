import axios from 'axios';
import ICompanyForm from '@/types/ICompanyForm';
import ICompanySettingsForm from '@/types/ICompanySettingsForm';
import {IUserCreateForm} from '@/types/IUserCreateForm';
import {IUserEditForm} from '@/types/IUserEditForm';
import ICompanyConfig from '@/types/ICompanyConfig';

export default class CompanyRepository {
    static fetchCompanyInfo(): Promise<any> {
        return axios.get('company/info');
    }

    static fetchCompanyUsers(page: number, size: number): Promise<any> {
        return axios.get(`company/user/${page}/${size}`);
    }

    static getUserRoles(): Promise<any> {
        return axios.get(`company/user/form`);
    }

    static updateCompanyAddress(data: ICompanyForm): Promise<any> {
        return axios.put(`company/address/${data.addressId}`, data);
    }

    static deleteCompanyUser(id: number): Promise<any> {
        return axios.delete(`company/user/${id}`);
    }

    static updateCompanySettings(data: ICompanySettingsForm): Promise<any> {
        return axios.post('company/params', data);
    }

    static createCompanyUser(data: IUserCreateForm): Promise<any> {
        return axios.post('company/user', data);
    }

    static updateCompanyUserRole(data: IUserEditForm): Promise<any> {
        return axios.put(`company/user/${data.id}/role`, {role: data.role});
    }

    static getCompanyConfig(): Promise<any> {
        return axios.get('company/configuration');
    }

    static getCompanyConfigForm(): Promise<any> {
        return axios.get('company/configuration/form');
    }

    static createCompanyConfig(data: ICompanyConfig): Promise<any> {
        return axios.post('company/configuration', data);
    }

    static revokeAuthorizationCompanyConfig(id: number): Promise<any> {
        return axios.delete(`company/configuration/authorize/${id}`);
    }

    static authorize(id: number): Promise<any> {
        return axios.get(`company/configuration/authorize/${id}`);
    }

    static exchange(id: number, code: string): Promise<any> {
        return axios.post(`company/configuration/exchange/${id}/${code}`);
    }

    //NEW
    static authorizeMarketing(): Promise<any> {
        return axios.get('company/configuration/authorize/marketing');
    }

    static exchangeMarketing(code: string): Promise<any> {
        return axios.post(`company/configuration/exchange/marketing/${code}`);
    }

    static authorizeSPAPI() {
        return axios.get('company/configuration/authorize/spapi')
    }

    static exchangeSPAPI(api: any): Promise<any> {
        return axios.post('company/configuration/exchange/spapi', api);
    }

    static async finishConfiguration(api: any) {
       return axios.post('company/configuration/finish', api);
    }

    static async getActualStep() {
        return axios.get('company/configuration/actual-step');
    }

}
