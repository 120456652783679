





















import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import TranslationService from './services/TranslationService';
import ITerm from '@/types/ITerms';
import AcceptTerms from '@/components/AcceptTerms.vue';
import store from '@/store';
import UserService from '@/services/UserService';
import {resolveError} from '@/utils/notifications';
import {ILanguage} from '@/types/TranslationTypes';

const Layout = namespace('layout');
const User = namespace('user');
const Translations = namespace('translations');

@Component({
  name: 'App',
  components: {
    AcceptTerms,
  },
})
export default class App extends Vue {
  @Layout.Mutation
  setWidth!: (width: number) => void;

  @Layout.Mutation
  setHeight!: (height: number) => void;

  @User.Getter
  getTermsToAccept!: ITerm[];

  @User.Getter
  isUser!: boolean;

  @Translations.Getter
  getCurrentLanguage!: string;

  @Translations.Getter
  languagesList!: ILanguage[];
  async mounted() {
    this.onResize();
    const language = await TranslationService.getCurrentLanguage();
    if (language) {
      this.$vuetify.lang.current = language.shortcut;
    }
    // if (this.isUser) {
    //   await this.checkTermsToAccept();
    // } else {
    //   setTimeout(() => {
    //     if (this.isUser) {
    //       this.checkTermsToAccept();
    //     }
    //   }, 500);
    // }
  }

  onResize() {
    this.setWidth(window.innerWidth);
    this.setHeight(window.innerHeight);
  }

  async checkTermsToAccept(repeated = false) {
    const language: ILanguage = this.prepareLanguage();

    if (!language.id && !repeated) {
      setTimeout(() => {
        this.checkTermsToAccept(true);
      }, 500);
      return;
    }

    try {
      const termsToAccept: ITerm[] = await UserService.getTermsToAccept(language);
      store.commit('user/setTermsToAccept', termsToAccept);
    } catch (e) {
      resolveError(e, 'getTerms');
    }
  }

  prepareLanguage(): ILanguage {
    return this.languagesList
        .find((element) => element.shortcut === this.getCurrentLanguage) || this.languagesList[0];
  }

  termsLogout() {
    store.commit('user/setTermsToAccept', []);
  }
}
