'use strict';

import Vue from 'vue';
import axios from 'axios';
import {getLocalStorage, removeLocalStorage} from '../utils/localStorage';
import router from 'vue-router';
import AuthService from '../services/AuthService';

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.headers.common.Authorization = getLocalStorage('token') || 'Basic YnJvd3Nlcjo=';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// const _axios = axios.create(config);

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    const expiresAt = localStorage.getItem('expires_at');
    if (expiresAt && expiresAt <= secondsSinceEpoch && config.url !== 'public/refresh' && config.url !== 'public/logout') {
      AuthService.refreshToken()
        .then(() => {
          return config;
        })
        .catch(async (e) => {
          await AuthService.logout();
          return config;
        });
    } else {
      return config;
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Logout user if token refresh didn't work or user is disabled
    if (error.config.url == '/public/refresh') {

      removeLocalStorage('token');
      removeLocalStorage('refresh_token');
      router.push({name: 'Login'});

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    return AuthService.refreshToken()
      .then(() => {
        return new Promise((resolve, reject) => {
          axios.request(error.config).then(response => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      })
      .catch((error) => {
        Promise.reject(error);
      });
  },
);

Plugin.install = function (Vue, options) {
  Vue.axios = axios;
  window.axios = axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios;
      },
    },
    $axios: {
      get() {
        return axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
