import {ILanguage, ITranslation} from '@/types/TranslationTypes';

export default class TranslationFactory {
  static toLang(data: any): ILanguage {
    return {
      id: data.id,
      name: data.name,
      shortcut: data.shortcut,
    };
  }

  static toTranslation(data: any): ITranslation {
    return {
      label: data.label,
      labelId: data.labelId,
      labelType: data.labelType,
      translation: data.translation,
      translationId: data.translationId,
    };
  }
}
