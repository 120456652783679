import ICompanyInfo from '@/types/ICompanyInfo';
import CompanyRepository from '@/repositories/CompanyRepository';
import CompanyFactory from '@/factories/CompanyFactory';
import ICompanyForm from '@/types/ICompanyForm';
import ICompanySettingsForm from '@/types/ICompanySettingsForm';
import UserFactory from '@/factories/UserFactory';
import {IUserCreateForm} from '@/types/IUserCreateForm';
import {IUserEditForm} from '@/types/IUserEditForm';
import {IPagination} from '@/types/IPagination';
import ICompanyConfig from '@/types/ICompanyConfig';
import store from '@/store';

export default class CompanyService {
    static async getCompanyInfo(): Promise<ICompanyInfo> {
        const {data} = await CompanyRepository.fetchCompanyInfo();
        return CompanyFactory.toCompanyInfo(data);
    }

    static async getCompanyUsers(params: IPagination): Promise<any> {
        const {data} = await CompanyRepository.fetchCompanyUsers(params.page, params.size);
        return UserFactory.toUsersList(data);
    }

    static async updateCompanyAddress(data: ICompanyForm): Promise<any> {
        return CompanyRepository.updateCompanyAddress(data);
    }

    static async deleteCompanyUser(id: number): Promise<any> {
        return CompanyRepository.deleteCompanyUser(id);
    }

    static async updateCompanySettings(data: ICompanySettingsForm): Promise<any> {
        return CompanyRepository.updateCompanySettings(data);
    }

    static async getUserRoles(): Promise<any> {
        return CompanyRepository.getUserRoles();
    }

    static async createCompanyUser(data: IUserCreateForm): Promise<any> {
        return CompanyRepository.createCompanyUser(data);
    }

    static async updateCompanyUserRole(data: IUserEditForm): Promise<any> {
        return CompanyRepository.updateCompanyUserRole(data);
    }

    static async getCompanyConfig(): Promise<any> {
        const {data} = await CompanyRepository.getCompanyConfig();
        return data;
    }

    static async getCompanyConfigForm(): Promise<any> {
        const {data} = await CompanyRepository.getCompanyConfigForm();
        return data;
    }

    static async createCompanyConfig(data: ICompanyConfig): Promise<any> {
        return await CompanyRepository.createCompanyConfig(data);
    }

    static async revokeAuthorizationCompanyConfig(id: number): Promise<any> {
        return await CompanyRepository.revokeAuthorizationCompanyConfig(id);
    }

    static async authorize(id: number): Promise<any> {
        const {data} = await CompanyRepository.authorize(id);
        return data;
    }

    static async exchange(id: number, code: string): Promise<any> {
        return await CompanyRepository.exchange(id, code);
    }

    //NEW
    static async authorizeSPAPI(): Promise<any> {
        const {data} = await CompanyRepository.authorizeSPAPI();
        return data;
    }

    static async exchangeSPAPI(api: any) {
        await CompanyRepository.exchangeSPAPI(api);
    }

    static async authorizeMarketing(): Promise<any> {
        const {data} = await CompanyRepository.authorizeMarketing();
        return data;
    }

    static async exchangeMarketing(code: string): Promise<any> {
        return await CompanyRepository.exchangeMarketing(code);
    }

    static async finishConfiguration(api: any): Promise<any> {
        const {data} = await CompanyRepository.finishConfiguration(api);
        return data;
    }

    static async getActualStep(forceRequest: boolean = false): Promise<number> {
        const step = store.state.user.actualStep
        if (step > 0 && !forceRequest) {
            return step
        }

        const {data}: { data: number } = await CompanyRepository.getActualStep();
        store.commit('user/setUserActualStep', data);

        return data
    }

}
