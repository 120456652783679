import {IUserCreateForm} from '@/types/IUserCreateForm';
import {IUsersList} from '@/types/IUsersList';
import {IUserEditForm} from '@/types/IUserEditForm';
import {IPaymentList} from '@/types/IPaymentList';

export default class UserFactory {
  static toCreateUser(data: any): IUserCreateForm {
    return {
      acceptedTerms: data.acceptedTerms || [],
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      mail: data.mail || '',
      password: data.password || '',
      role: data.role || '',
    };
  }

  static toEditUser(data: any): IUserEditForm {
    return {
      acceptedTerms: data.acceptedTerms,
      firstName: data.firstName,
      lastName: data.lastName,
      mail: data.mail,
      role: data.role,
      id: data.id,
    };
  }

  static toUsersList(data: any): IUsersList {
    return {
      content: data.content || [],
      number: data.number || 1,
      size: data.size || 10,
      totalElements: data.totalElements || 0,
    };
  }

  static toPaymentsList(data: any): IPaymentList {
    let returnData: IPaymentList = data;
    if (data.data && Array.isArray(data.data.content)) {
      returnData = data.data;
    }
    return {
      content: returnData.content || [],
      number: returnData.number || 1,
      size: returnData.size || 10,
      totalElements: returnData.totalElements || 0,
    };
  }
}
