import {Module, Mutation, VuexModule} from 'vuex-module-decorators';

@Module({
  namespaced: true,
  name: 'layout',
})
export default class Layout extends VuexModule {
  width: number = 0;
  height: number = 0;
  isMinimized: boolean = true;

  get isMobile(): boolean {
    return this.width < 1264;
  }

  get layoutSize(): { width: number, height: number } {
    return {
      width: this.width,
      height: this.height,
    };
  }

  @Mutation
  setWidth(newWidth: number): void {
    this.width = newWidth;
  }

  @Mutation
  setHeight(newHeight: number): void {
    this.height = newHeight;
  }

  @Mutation
  setMenuState(newState: boolean): void {
    this.isMinimized = newState;
  }
}
