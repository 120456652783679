import ICompanyInfo from '@/types/ICompanyInfo';
import ICompanyConfig from '@/types/ICompanyConfig';
import ICompanyConfigForms from '@/types/ICompanyConfigForms';

export default class CompanyFactory {
  static toCompanyInfo(data: any): ICompanyInfo {
    return {
      address: {
        addressId: data.address.addressId,
        city: data.address.city,
        postalCode: data.address.postalCode,
        address: data.address.address,
        countryId: data.address.countryId,
        companyName: data.name,
        nip: data.nip
      },
      id: data.id,
      name: data.name,
      nip: data.nip,
      params: {
        accessKey: data.params.accessKey,
        authToken: data.params.authToken,
        clientSecret: data.params.clientSecret,
        developerId: data.params.developerId,
        reportDate: data.params.sendDate,
        sellerId: data.params.sellerId,
        defaultCurrency: data.params.defaultCurrency
      },
    };
  }

  static toConfigCreate(data: any = {}): ICompanyConfig {
    return {
      authorized: false,
      authorizedAt: '',
      clientTypeId: 0,
      clientTypeName: '',
      configurationId: 0,
      createdAt: '',
      regionId: 0,
      regionName: '',
      regionSymbol: '',
      sellerId: ''
    };
  }

}
