import {ILanguage} from '@/types/TranslationTypes';
import {Module, Mutation, VuexModule} from 'vuex-module-decorators';

@Module({
  namespaced: true,
  name: 'translations',
})
export default class Translations extends VuexModule {
  languages: ILanguage[] = [];
  currentLanguage: string = '';

  get language(): ILanguage | null {
    const candidate = this.languages.find((item) => item.shortcut === this.currentLanguage);

    return candidate || null;
  }

  get getCurrentLanguage() {
    return this.currentLanguage;
  }

  get languagesList(): ILanguage[] {
    return this.languages;
  }

  @Mutation
  setLanguages(languages: ILanguage[]): void {
    this.languages = languages;
  }

  @Mutation
  setCurrentLanguage(languageShortcut: string): void {
    this.currentLanguage = languageShortcut;
  }
}
