import TranslationService from '@/services/TranslationService';
import {ILanguage} from '@/types/TranslationTypes';
import {resolveError} from '@/utils/notifications';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  silentTranslationWarn: true,
});

export async function translateApp() {
  await TranslationService.getLanguagesList();

  const language: ILanguage | undefined = await TranslationService.getCurrentLanguage();

  if (language) {
    await getTranslations(language);
  }
}

async function getTranslations(lang: ILanguage) {
  try {
    i18n.setLocaleMessage(lang.shortcut, await TranslationService.getTranslations(lang.id!, true));
    setI18nLanguage(lang.shortcut);
  } catch (e) {
    resolveError(e, 'translate');
  }
}

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  document!.querySelector('html')!.setAttribute('lang', lang);
  return lang;
}
